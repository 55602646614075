import { ActionReducerMap } from '@ngrx/store';
import { authorizationReducer } from '../authorization/store/authorization.reducer';
import {
  authorizationFeatureKey,
  AuthorizationState,
} from '../authorization/store/authorization.state';

export interface RootState {
  [authorizationFeatureKey]: AuthorizationState;
}

export const rootReducers: ActionReducerMap<RootState> = {
  [authorizationFeatureKey]: authorizationReducer,
};
