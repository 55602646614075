import 'hammerjs';

import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, } from '@angular/common/http';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, provideAnimations, } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule, TranslateService, } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxLoadingModule } from 'ngx-loading';
import { JwtInterceptor } from 'src/app/authorization/interceptors/jwt-interceptor';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { rootReducers } from './state/store.index';
import { CommonModule } from '@angular/common';
import { debug, storageMetaReducer } from './state/meta-reducers.index';
import { authorizationFeatureKey, authorizationPersistKeys, } from './authorization/store/authorization.state';
import {
    API_BASE_URL,
    HttpClientAuthentication,
} from './shared/nswag.api';
import { RouterModule, Routes, Router, NavigationEnd } from '@angular/router';
import { LoggedInGuard } from './authorization/guards/logged-in.guard';
import { AuthorizationEffects } from './authorization/store/authorization.effects';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { isUserTokenPresent } from './authorization/store/authorization.selectors';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs';
import { AngularSvgIconModule } from 'angular-svg-icon';
import {
    ChangePasswordComponentComponent
} from './authorization/pages/change-password-component/change-password-component.component';
import { NavigationService } from './shared/services/navigation-service.service';
import { ErrorInterceptor } from 'src/error.interceptor';
import { ToastrModule, provideToastr } from 'ngx-toastr';
import { SIGNAL_BASE_URL } from './shared/signalr.api';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () =>
            import('./authorization/authorization.module').then(
                m => m.AuthorizationModule
            ),
    },
    {
        path: 'change-password',
        component: ChangePasswordComponentComponent,
    },
    {
        path: '',
        canActivateChild: [LoggedInGuard],
        canActivate: [LoggedInGuard],
        loadChildren: () =>
            import('./admin-layout/admin-layout.module').then(
                m => m.AdminLayoutModule
            ),
    },
    { path: '**', redirectTo: 'auth', pathMatch: 'full' },
];

@NgModule({
    declarations: [AppComponent],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,


        RouterModule.forRoot(routes),
        AngularSvgIconModule.forRoot(),

        // translate
        TranslateModule.forRoot({
            defaultLanguage: 'sq',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),

        // loading
        NgxLoadingModule.forRoot({
            backdropBorderRadius: '3px',
            fullScreenBackdrop: true,
        }),

        // ngrx
        StoreModule.forRoot(rootReducers, {
            metaReducers: [
                debug,
                storageMetaReducer<any>(
                    [
                        ...authorizationPersistKeys,

                        ...authorizationFeatureKey,
                    ],
                    'root'
                ),
            ],
        }),

        EffectsModule.forRoot([AuthorizationEffects, HttpClientAuthentication]),
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production, // Restrict extension to log-only mode
        }),

        // ng-select
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        ToastrModule.forRoot(),

        AngularSvgIconModule.forRoot(),
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        {
            provide: API_BASE_URL,
            useValue: environment.baseUrl,
        },

        // TODO
        {
            provide: SIGNAL_BASE_URL,
            useValue: environment.signalRUrl,
        },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

        NavigationService,
        provideAnimations(),
        provideToastr({
            autoDismiss: true,
            positionClass: 'toast-bottom-center',
            preventDuplicates: true,
            closeButton: true,
            countDuplicates: true,
            extendedTimeOut: 1000,
            resetTimeoutOnDuplicate: true,
            tapToDismiss: false,
            maxOpened: 5,
            iconClasses: {
                error: 'toast-error',
                info: 'toast-info',
                success: 'toast-success',
                warning: 'toast-warning',
            },
            progressBar: true,
        }),
    ],
    bootstrap: [AppComponent],
    schemas: [NO_ERRORS_SCHEMA],
    exports: [],
})
export class AppModule {

    constructor(
        private store: Store, // private ngSelectConfig: NgSelectConfig, // private translate: TranslateService
        private translateSvc: TranslateService,
        private router: Router
    ) {
        this.routeEvent(this.router);
        translateSvc.use(translateSvc.getDefaultLang());
        this.store.select(isUserTokenPresent)
            .pipe(
                distinctUntilChanged(),
                debounceTime(100),
                filter(isUserTokenPresent => isUserTokenPresent),
            ).subscribe();
    }


    routeEvent(router: Router) {
        router.events.subscribe(e => {
            if (e instanceof NavigationEnd) {
                if (e.url === '/' || e.url === '/no-permission') {
                    this.router.navigate(['/dashboard']);
                }
            }
        });
    }

}